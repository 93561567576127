import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table } from 'react-bootstrap';
import SidebarPdf from "../../components/SidebarPdf";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSampleWorksheets, deleteSampleWorksheet } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';

const SampleWorksheets = () => {
  const navigate = useNavigate();
  const [worksheets, setWorksheets] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    institute_id: '',
    class_id: '',
    subject_id: '',
    status: '',
    title: '',
    pdf: null,
  });

  const [editMode, setEditMode] = useState(false);

  const [questions, setQuestions] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);

  // Fetch sample worksheets
  const fetchWorksheets = async () => {
    try {
      const response = await fetchSampleWorksheets(selectedClassId, selectedSubjectId);
      setWorksheets(response.data.data);
    } catch (error) {
      console.error('Error fetching sample worksheets:', error);
    }
  };

  useEffect(() => {
    fetchWorksheets();
  }, [selectedClassId, selectedSubjectId]);

  

  const handleEdit = (id) => {
    navigate(`/edit-sample-worksheet/${id}`)
  };

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (!userConfirmed) {
      return false;
    } 
    try {
      await deleteSampleWorksheet(id)
      alert('Sample worksheet deleted successfully!');
      fetchWorksheets();
    } catch (error) {
      console.error('Error deleting sample worksheet:', error);
      alert('Failed to delete sample worksheet.');
    }
  };

  return (
    <div className="wrapper">
    
    <div className="row">
        <SidebarPdf></SidebarPdf>
        <div className="container mt-4 col-md-9 col-lg-10">
          <h3>Sample Worksheet List</h3>

          <div className="class-info">
              <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
              <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
          </div>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Download Link</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {worksheets.map((worksheet, i) => (
                    <tr key={worksheet.id}>
                    <td>{i+1}</td>
                    <td>{worksheet.title}</td>
                    <td>
                      {worksheet.downloadLink == '' ? (<div></div>) : (
                        <a href={worksheet.downloadLink} download={worksheet.downloadLink} target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary" >Download PDF </a>
                      )}
                    
                    </td>
                    <td>{worksheet.status}</td>
                    <td>
                        <Button variant="warning" onClick={() => handleEdit(worksheet.id)} size="sm">
                        Edit
                        </Button>{' '}
                        <Button variant="danger" onClick={() => handleDelete(worksheet.id)} size="sm">
                        Delete
                        </Button>
                    </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
      </div>
    </div>
  );
};

export default SampleWorksheets;
