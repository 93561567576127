import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClasses, selectClass, SELECT_CLASS_ID } from '../actions/classActions';
import { getSubjects, RESET_SUBJECT, selectSubject, SELECT_SUBJECT_ID } from '../actions/subjectActions';
import { getTopics, RESET_TOPICS, selectTopic } from '../actions/topicActions';
import { getSubTopics, selectSubTopic, selectSubTopicId, RESET_SUBTOPICS } from '../actions/subTopicActions';
import { getQuestions } from '../actions/questionActions';
import { getWorksheets} from '../actions/worksheetActions'


import "../styles/question-list.css"

const SidebarPdf = () => {
  
  
  const dispatch = useDispatch();
  const {classList, selectedClass} = useSelector((state) => state.classes);
  const {subjectList, selectedSubject} = useSelector((state) => state.subjects);
  const {topicList, selectedTopic} = useSelector((state) => state.topics);
  const {subTopicList, selectedSubTopic} = useSelector((state) => state.subtopics);


 
  useEffect(() => {
    dispatch(getClasses());
  }, []);


  const handleClassChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const className = selectedOption.value;
    const classId = selectedOption.getAttribute('data-id');
    dispatch(selectClass(className));
    dispatch(getSubjects(classId));
    dispatch({ type: SELECT_CLASS_ID, payload: classId });
    dispatch({ type: RESET_TOPICS, payload: [] });
    dispatch({ type: RESET_SUBTOPICS, payload: [] });
  };

  const handleSubjectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const subjectName = selectedOption.value;
    const subjectId = selectedOption.getAttribute('data-id');
    dispatch({ type: SELECT_SUBJECT_ID, payload: subjectId });
    dispatch(selectSubject(subjectName));
    dispatch(getTopics(subjectId));
  };
    

  return (
    <nav className="sidebar col-md-3 col-lg-2 d-md-block" id="sidebarMenu">

    <ul className="vertical-menu">
        <li>
        <select className="form-select" value={selectedClass} onChange={handleClassChange}>
            <option>Select Class</option>
            {classList.data && classList.data.map((classItem) => (
                <option key={classItem.id} data-id={classItem.id} value={classItem.class_name}>
                {classItem.class_name}
                </option>
            ))}
        </select>
        </li>
        <li>
     
        <select className="form-select" value={selectedSubject} onChange={handleSubjectChange}>
            <option>Select Subject</option>
            {subjectList.data && subjectList.data.map((Item) => (
                <option key={Item.id} data-id={Item.id} value={Item.subject_name}>
                {Item.subject_name}
                </option>
            ))}
        </select>
        </li>
    </ul>

  
    
   
    </nav>
  );
};

export default SidebarPdf;
