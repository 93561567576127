const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    teacher: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          token: action.payload.token,
          isAuthenticated: true,
          teacher: action.payload.teacher,
        };
      case 'TEACHER_LOADED':
        return {
          ...state,
          isAuthenticated: true,
          teacher: action.payload,
        };
      case 'LOGOUT':{
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          teacher: null,
        };
      }
      case 'AUTH_ERROR':
      case 'LOGIN_FAIL':
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          teacher: null,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  