import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_PAPER_TITLE } from '../actions/paperActions';

const PaperTitle = () => {
    const [isEditTitle, setIsEditTitle] = useState(false);
   

    const {selectedPaper} = useSelector((state) => state.papers);
    const [title, setTitle] = useState(selectedPaper.title);

    const dispatch = useDispatch();

    const paperRef = useRef()
  

    let titleInput = title;

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

   
    const handleTitleSave = ()=>{
        setIsEditTitle(false)
        dispatch({
            'type': UPDATE_PAPER_TITLE,
            'payload': title
        })
    }
    

    if(selectedPaper.title != '' && !isEditTitle){
        return(
            <div className="row mb-2"> 
                <div className="col-md-10">
                    <p>{selectedPaper.title}</p>
                </div>
                <div className="col-md-2">
                    <button className="btn btn-primary text-end btn-sm" onClick={()=>{setIsEditTitle(true)}}> Edit </button>
                    
                </div>
            </div>
        )
    }
    else if(selectedPaper.title != '' && isEditTitle){
        return(
            <div className="row mb-2">
                    <div className="col-md-10">
                        <input type="text" className="form-control" ref={paperRef} onChange={handleTitleChange} value={titleInput}  placeholder="Enter title" required/>
                        
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-primary text-end btn-sm" onClick={handleTitleSave}> Save</button>
                    </div>
            </div>
        )
    }
    else{
        return(
            <div className="row mb-2">
                    <div className="col-md-10">
                        <input type="text" className="form-control" ref={paperRef} onChange={handleTitleChange} value={titleInput}  placeholder="Enter title" required/>
                        
                    </div>
                    <div className="col-md-2 ">
                        <button className="btn btn-primary text-end btn-sm" onClick={handleTitleSave}> Save</button>
                    </div>
            </div>
        )
    }

   
};

export default PaperTitle