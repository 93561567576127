import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  const storedToken = localStorage.getItem("token");

  return storedToken ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
