import { GET_TOPICS, SELECT_TOPIC, RESET_TOPICS } from '../actions/topicActions';

const initialState = {
  topicList: [],
  loading: false,
  error: null,
  selectedTopic: ''
};

const topicReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOPICS:
      return { ...state, topicList: action.payload, loading: false };
    case SELECT_TOPIC:
      return { ...state, selectedTopic: action.payload, loading: false };  
    case RESET_TOPICS:
      return { ...state, topicList: action.payload, loading: false };   
    default:
      return state;
  }
};

export default topicReducer;
