import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import '../../styles/PaperView.css'
import { useNavigate } from 'react-router-dom';
import { UPDATE_PAPER_SECTION, UPDATE_PAPER_SUBTITLE, UPDATE_PAPER_TITLE } from '../../actions/worksheetActions';
import { createQuestionPaper } from '../../api';


  const MultipleChoiceQuestion = ({ question, options, name }) => {
    return (
      <div className="mb-3">
        <p>{question}</p>
        <ul className="list-unstyled">
          {options.map((option, index) => (
            <li key={index}>
              <input type="radio" name={name} /> {option}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  const ShortAnswerQuestion = ({ question, name }) => {
    return (
      <div className="mb-3">
        <p>{question}</p>
      </div>
    );
  };

  const Wrapper = ({ id, questionString }) => {
    const question = JSON.parse(questionString);
    if(question.type == 'multiple'){
      question.options = JSON.parse(question.options)
    }
    console.log('question')
    console.log(question)
    return(
      <div>
          <p>{id+1}. <span className='mt-4 2rem' dangerouslySetInnerHTML={{ __html: question.question }} ></span></p>
          {question.type === "multiple" && (
                <ul>
                  {question.options.map((option, idx) => (
                    <li key={idx}>{option}</li>
                  ))}
                </ul>
          )}
      </div>
    )
  
     
  };
  
  
  const QuestionHtmlView = (selectedPaper) => {
    let paperString = JSON.stringify(selectedPaper.paper)
    let paperData = JSON.parse(paperString)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
    const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
    console.log('paperData') 
    console.log(paperData) 

    const dataArray = [
      { id: 1, title: "Page 1", content: "Content for the first page." },
    ];


    const addQuestionPaper = async() => { 
      let questionPdfData = {
        class_id: selectedClassId,
        className: selectedClass,
        subject_id: selectedSubjectId,
        subjectName: selectedSubject,
        schoolName: paperData.school_name,
        paperData: JSON.stringify(paperData),
        title: paperData.title,
        subtitle: paperData.subtitle,
        downloadCount: 0,
      }

      if(paperData.title == ''){
        alert('Please go on paper creator page and enter title');
        return false;
      }

      const response = await createQuestionPaper(questionPdfData);
      console.log(response.data)
      alert('Message Sent')
      dispatch({'type': UPDATE_PAPER_TITLE, 'payload': ''});
      dispatch({'type': UPDATE_PAPER_SUBTITLE, 'payload': ''});
      dispatch({'type': UPDATE_PAPER_SECTION, 'payload': []})
      navigate('/question-paper-list')

      // axios
      //   .post("http://localhost/questions-creator/public/api/question-paper/create", questionPdfData, {
      //     headers: {
      //         'Content-Type': 'multipart/form-data',
      //     }
      // })
      //   .then((response) => {
      //     console.log(response.data)
      //     alert('Message Sent')
      //     dispatch({'type': UPDATE_PAPER_TITLE, 'payload': ''});
      //     dispatch({'type': UPDATE_PAPER_SUBTITLE, 'payload': ''});
      //     dispatch({'type': UPDATE_PAPER_SECTION, 'payload': []})
      //     navigate('/question-paper-list')
      //     // setWorksheets([...worksheets, response.data]);
      //     // setNewWorksheet({
      //     //   schoolName: "",
      //     //   title: "",
      //     //   subtitle: "",
      //     //   status: "Active",
      //     //   downloadCount: 0,
      //     // });
      //   })
      //   .catch((error) => console.error("Error adding worksheet:", error));
    };

    if(paperData.sections.length == 0){
      return(
        <div className="container mt-5">
          <h1>Data not found</h1>
              <div className='row' >
                <div className='col-md-2'>
                    <div>
                        {dataArray.map((item, index) => (
                          <div
                            key={item.id}
                            style={{
                              height: "480px", // A4 page height
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              pageBreakAfter: index === dataArray.length - 1 ? "auto" : "always",
                            }}
                          >
                          </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
      )
    }

   
   
    
    return (
      <div className="container"    >
       <div className='row' >
        <div className='col-md-2'>
            <div>
                {dataArray.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                    //   border: "2px dashed #000",
                      // padding: "20px",
                      // marginBottom: "20px",
                      height: "1090px", // A4 page height
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      pageBreakAfter: index === dataArray.length - 1 ? "auto" : "always",
                    }}
                  >
                    {/* <h1>{index + 1}</h1> */}
                  </div>
                ))}
            </div>
        </div>
        <div className='col-md-8' style={{maxWidth: '790px'}} >
            <div className="container mt-2">
              
              {/* Header Section */}
              <header className="text-center mb-4">
                <h2>{paperData.school_name}</h2>
                <h3>{paperData.title}</h3>
                <p>{paperData.subtitle}</p>
              </header>
        
            
        
              {/* Section B: Short Answer Questions */}
              { paperData.sections && paperData.sections.map((section, i)=>{
                  return (
                    <section className="mb-4" style={{marginLeft: 30, marginRight: 30}} key={i}>
                      <h3>{section.section_name}</h3>
                    
                      {section.questions && section.questions.map((question, index) => {

                          return (
                                <div className="row">
                                    <div className="col-md-10">
                                      <Wrapper id={index} questionString={JSON.stringify(question)}></Wrapper>
                                    </div>
                                    <div className="col-md-2">
                                        <p style={{float: 'right'}}>Marks: {question.marks}</p>
                                    </div>
                                </div>
                          )

                      })}  
                    
                    </section>
                  )
              
              })}
            
        
            
            </div>
         </div>
         <div className='col-md-2'>  
                 <button className='btn btn-primary mt-4' onClick={addQuestionPaper}>Send Pdf Request</button>
        </div>
        </div>
      </div>
    );
  };
  

// Document Component
const QuestionPaperView = () => {
  const contentRef = useRef();
  const [equation, setEquation] = useState('')

  const {selectedPaper} = useSelector((state) => state.papers);

  const handleDownload = () => {
    const element = contentRef.current;
    html2pdf().from(element).save();
  };

  
  
  return(
    <div>
        <div ref={contentRef}>
      
          <QuestionHtmlView paper={selectedPaper} ></QuestionHtmlView>
        
      
        </div>
   
    </div>
  );

}

  export default QuestionPaperView;

