import React from 'react';
import { useSelector } from 'react-redux';

const TeacherProfile = () => {
  const teacher = useSelector((state) => state.auth.teacher);

  return (
    <div className="container">
      <h1>Welcome, {teacher ? teacher.name : 'Guest'}</h1>
      <p>Email: {teacher ? teacher.email : 'Not available'}</p>
    </div>
  );
};

export default TeacherProfile;
