// src/api.js
import axios from 'axios';





axios.interceptors.response.use(
    (response) => {
        // Ensure there's no manipulation of Content-Length
        if (response.headers['content-length']) {
            delete response.headers['content-length'];
        }
   
        return response;
    },
    (error) => {
       
        // Handle errors
        if (error.response) {
          // Server responded with a status code outside the 2xx range
          const statusCode = error.response.status;
          const errorMessage = error.response.data.message || "Something went wrong";
    
          // Example: Log the error or handle specific status codes
          console.error(`Error: ${errorMessage} (Status Code: ${statusCode})`);

          if(statusCode == 401){
            localStorage.removeItem('token');
          }
    
          // Return a Promise rejection with the error details
          return error;
        } 
      }
);


axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

const API_URL = 'https://studentgrows.com/api/public/api'; // Replace with your backend URL
const LOGIN_URL = 'https://studentgrows.com/api/public'; // Replace with your backend URL

// const API_URL = 'http://localhost/questions-creator/public/api'; // Replace with your backend URL
// const LOGIN_URL = 'http://localhost/questions-creator/public'; // Replace with your backend URL

export const loginTeacher = (phone, password) => axios.post(`${LOGIN_URL}/login`,  { phone, password }, {
    headers: { 'Content-Type': 'multipart/form-data' },
});

// Classes
export const fetchClasses = () => axios.get(`${API_URL}/classes`);
export const createClass = (data) => axios.post(`${API_URL}/classes`, data);
export const updateClass = (id, data) => axios.put(`${API_URL}/classes/${id}`, data);
export const deleteClass = (id) => axios.delete(`${API_URL}/classes/${id}`);

// Similarly for Subjects, Topics, Subtopics, and Questions
export const fetchSubjects = (classId) => axios.get(`${API_URL}/subjects/${classId}`);

export const fetchTopics = (subjectId) => axios.get(`${API_URL}/topics/${subjectId}`);

export const fetchSubTopics = (topicId) => axios.get(`${API_URL}/subtopics/${topicId}`);

export const fetchQuestions = (subTopicId) => axios.get(`${API_URL}/questions/${subTopicId}`);

export const createQuestion = (data) => axios.post(`${API_URL}/questions`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
});

export const fetchQuestion = (questionId) => axios.get(`${API_URL}/questions/getQuestion/${questionId}`);

export const updateQuestion = (data) => axios.post(`${API_URL}/questions/update/${data.id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
});

export const fetchWorksheets = (subTopicId) => axios.get(`${API_URL}/worksheets/${subTopicId}`);
export const fetchWorksheet = (worksheetId) => axios.get(`${API_URL}/worksheet/find/${worksheetId}`);
export const createWorksheet = (worksheet) => axios.post(`${API_URL}/worksheet/create`, worksheet, {
    headers: {
    "Content-Type": "multipart/form-data",
    },
});

export const updateWorksheet = (worksheetId, worksheet) => axios.post(`${API_URL}/questions/update/${worksheetId}`, worksheet, {
    headers: { 'Content-Type': 'multipart/form-data' },
});
export const deleteWorksheet = (worksheetId, worksheet) => axios.post(`${API_URL}/questions/delete/${worksheetId}`, worksheet, {
    headers: { 'Content-Type': 'multipart/form-data' },
});
export const uploadWorksheetContentImage = (formData) => axios.post(`${API_URL}/worksheet/uploadImage`, formData, {
    headers: {
    "Content-Type": "multipart/form-data",
    },
});
export const fetchWorksheetsPdf = (classId, subjectId) => axios.get(`${API_URL}/worksheet-pdf/list/${classId}/${subjectId}`);
export const worksheetPdfUpload = (paperId, formData) => axios.post(`${API_URL}/worksheet-pdf/uploadPdf/${paperId}`, formData, {
    headers: {
    "Content-Type": "multipart/form-data",
    },
});
export const createWorksheetPdf = (worksheetPdfData) => axios.post(`${API_URL}/worksheet-pdf/create`, worksheetPdfData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const fetchWorksheetPdf = (id) => axios.get(`${API_URL}/worksheet-pdf/find/${id}`);

export const questionPdfUpload = (paperId, formData) => axios.post(`${API_URL}/question-paper/uploadPdf/${paperId}`, formData, {
    headers: {
    "Content-Type": "multipart/form-data",
    },
});

export const fetchQuestionPapers = (classId, subjectId) => axios.get(`${API_URL}/question-paper/list/${classId}/${subjectId}`);
export const fetchQuestionPaper = (id) => axios.get(`${API_URL}/question-paper/find/${id}`);
export const createQuestionPaper = (questionPdfData) => axios.post(`${API_URL}/question-paper/create`, questionPdfData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const fetchSamplePapers = (classId, subjectId) => axios.get(`${API_URL}/sample-paper/list/${classId}/${subjectId}`);
export const fetchSamplePaper = (id) => axios.get(`${API_URL}/sample-paper/find/${id}`);
export const createSamplePaper = (data) => axios.post(`${API_URL}/sample-paper/create`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const updateSamplePaper = (id, data) => axios.post(`${API_URL}/sample-paper/update/${id}`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const deleteSamplePaper = (id) => axios.post(`${API_URL}/sample-paper/delete/${id}`, {}, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

export const fetchSampleWorksheets = (classId, subjectId) => axios.get(`${API_URL}/sample-worksheet/list/${classId}/${subjectId}`);
export const fetchSampleWorksheet = (id) => axios.get(`${API_URL}/sample-worksheet/find/${id}`);
export const createSampleWorksheet = (data) => axios.post(`${API_URL}/sample-worksheet/create`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const updateSampleWorksheet = (id, data) => axios.post(`${API_URL}/sample-worksheet/update/${id}`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
export const deleteSampleWorksheet = (id) => axios.post(`${API_URL}/sample-worksheet/delete/${id}`, {}, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});



