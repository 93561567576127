import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchQuestions, createQuestion, updateQuestion, fetchQuestion } from '../../api';
import Sidebar from '../../components/Sidebar';

const QuestionForm = () => {
  const [questions, setQuestions] = useState([
    { type: "text", question: "", questionLatex: "", answer: "", marks: "", options: [] },
  ]);

  const navigate = useNavigate();

  const {selectedClass} = useSelector((state) => state.classes);
  const {selectedSubject} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);
  const {selectedSubTopic, selectedSubTopicId} = useSelector((state) => state.subtopics);

  const handleTypeChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].type = value;
    if (value === "text") {
      updatedQuestions[index].options = []; // Clear options for text type
    }
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (index, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const addOption = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push("");
    setQuestions(updatedQuestions);
  };

  const removeOption = (index, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { type: "text", question: "", answer: "", marks: "", options: [] },
    ]);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        "subtopic_id": selectedSubTopicId,
        "questions": questions,
      }
      //console.log(data)
      const response = await createQuestion(data);
      console.log('response')
      console.log(response)
      
      alert("Questions submitted successfully!");
      navigate('/questions')
    } catch (error) {
      console.error("Error submitting questions:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const { id } = useParams(); 

  let questionId = id;

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await fetchQuestion(questionId)
        //const response = await axios.get(`http://localhost/questions-creator/public/api/questions/getQuestion/${questionId}`);
        let question = response.data.data[0];
        console.log('question')
        console.log(question)
        question.options = JSON.parse(question.options);
        let editedQuestion = [];
        editedQuestion.push(question);
        setQuestions([...editedQuestion])
        
      } catch (error) {
        console.error('Error fetching worksheet:', error);
      }
    };
    if(questionId){
      fetchQuestion();
    }
    
  }, [questionId]);


  const parseQuestionString = (questionInput)=>{
    let start = 0
    let latexStr = ''
    let latexArr = []
    let questionString = ''

    for(let i = 0; i < questionInput.length; i++){
       if(questionInput[i] == '$' && start == 0){
            start = 1
            continue
       }
       if(questionInput[i] == '$' && start == 1){
         const svgNode = window.MathJax.tex2svg(latexStr); // This returns a full MathJax-generated DOM structure
         let svg = svgNode.querySelector('svg');

         start = 0
         questionString = questionString + svg.outerHTML
         latexArr.push(latexStr)
         latexStr = ''
         continue
       }
       if(start == 1){
            latexStr = latexStr + questionInput[i]
       }
       if(start == 0){
            questionString = questionString + questionInput[i]
       }
    }
   return questionString;

 } 
 
 const handleQuestionLatex = (index) => {
  const updatedQuestions = [...questions];
  updatedQuestions[index]['questionLatex'] = parseQuestionString(updatedQuestions[index]['question']) ;

  console.log('updatedQuestions')
  console.log(updatedQuestions)
  setQuestions(updatedQuestions);
};

  return (
    <div className="wrapper"> 
      <div className="row">
          <Sidebar></Sidebar>
          <div className="container mt-2 col-md-9 col-lg-10">
            <div className="row">
              <div className="col-md-8">
                  <h3>Create Questions</h3>
                  <div className="class-info">
                      <p><span style={{marginRight:20}}><strong>Class:</strong> {selectedClass}</span><span><strong>Subject:</strong> {selectedSubject}</span></p>
                      <p><span style={{marginRight:20}}><strong>Topic:</strong> {selectedTopic}</span><span><strong>SubTopic:</strong> {selectedSubTopic}</span></p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    {questions.map((q, index) => (
                      <div className="mb-3 border p-3 rounded" key={index}>
                        <label className="form-label">Question Type</label>
                        <div className="form-check">
                          <input
                            type="radio"
                            name={`type-${index}`}
                            value="text"
                            className="form-check-input"
                            checked={q.type === "text"}
                            onChange={(e) => handleTypeChange(index, e.target.value)}
                          />
                          <label className="form-check-label">Text</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            name={`type-${index}`}
                            value="multiple"
                            className="form-check-input"
                            checked={q.type === "multiple"}
                            onChange={(e) => handleTypeChange(index, e.target.value)}
                          />
                          <label className="form-check-label">Multiple Choice</label>
                        </div>

                        <label className="form-label mt-2">Question</label>
                        <textarea 
                          className="form-control"
                          value={q.question}
                          onChange={(e) =>
                            handleQuestionChange(index, "question", e.target.value)
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={()=>{handleQuestionLatex(index)}}
                        >
                          Render
                        </button>

                        <p style={{marginBottom:30, marginTop:10}} className='2rem' dangerouslySetInnerHTML={{ __html: q.questionLatex }}></p>
                       
                        {q.type === "multiple" && (
                          <div className="mt-2">
                            <label className="form-label">Options</label>
                            {q.options.map((option, optionIndex) => (
                              <div key={optionIndex} className="d-flex align-items-center mb-2">
                                <input
                                  type="text"
                                  className="form-control me-2"
                                  value={option}
                                  onChange={(e) =>
                                    handleOptionChange(index, optionIndex, e.target.value)
                                  }
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeOption(index, optionIndex)}
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => addOption(index)}
                            >
                              Add Option
                            </button>
                          </div>
                        )}

                        <label className="form-label mt-2">Answer</label>
                        <input
                          type="text"
                          className="form-control"
                          value={q.answer}
                          onChange={(e) => handleQuestionChange(index, "answer", e.target.value)}
                        />

                        <label className="form-label mt-2">Marks</label>
                        <input
                          type="number"
                          className="form-control"
                          value={q.marks}
                          onChange={(e) => handleQuestionChange(index, "marks", e.target.value)}
                        />

                        <div className="mt-2">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm me-2"
                            onClick={() => removeQuestion(index)}
                          >
                            Remove Question
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary mb-3"
                      onClick={addQuestion}
                    >
                      Add Question
                    </button>
                    <br />
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </form>
              </div>
              <div className="col-md-4">
                    <h4>Documentation</h4>
                    <p>This section provides guidance on how to write mathematical expressions:</p>
                    <ul>
                        <li><strong>Inline Math:</strong> Use <code>$...$</code> for inline expressions, e.g., <code>$a^2 + b^2 = c^2$</code>.</li>
                        <li><strong>Block Math:</strong> Use <code>$$...$$</code> for block expressions, e.g., <code>$$\int_0^\infty e^-x^2 dx = \frac \sqrt \pi {2}$$</code>.</li>
                        <li><strong>Fractions:</strong> Use <code>\frac numerator denominator </code>, e.g., <code>$\frac{1}{2}$</code>.</li>
                        <li><strong>Square Roots:</strong> Use <code>\sqrt expression</code>, e.g., <code>$\sqrta^2 + b^2$</code>.</li>
                        <li><strong>Summation:</strong> Use <code>\sum_n=1^\infty</code> for summations.</li>
                        <li><strong>Greek Letters:</strong> Use <code>\alpha, \beta, \gamma, ...</code> for Greek letters, e.g., <code>$\alpha + \beta = \gamma$</code>.</li> 
                    </ul>
              </div>
            </div>
            
          </div>
      </div>
    </div>
  );
};

export default QuestionForm;
