import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useRef } from 'react';
import {  UPDATE_PAPER_SECTION } from '../../actions/worksheetActions';
import { UPDATE_PAPER_SECTION_WORKSHEET } from '../../actions/paperActions';

import 'bootstrap-icons/font/bootstrap-icons.css';

import Papertitle from '../../components/PaperTitle'
import CreateSection from '../../components/CreateSection'
import PaperSubTitle from '../../components/PaperSubTitle';
import EditSection from '../../components/EditSection';
import EdiWorksheet from '../../components/EditWorksheet';
import Sidebar from '../../components/Sidebar';

import '../../styles/CreatePaper.css'
import "../../styles/question-list.css"
import { redirect, useNavigate  } from 'react-router-dom';

const Wrapper = ({ id, worksheet_text }) => {
    return <p>{id+1}. <span className='mt-4 2rem' dangerouslySetInnerHTML={{ __html: worksheet_text }} ></span></p>;
};

const CreateWorksheetPdf = () => {
  
    const [paperPreview, setPaperPreview] = useState(false);
    const [isWorksheetScreenFullWidth, setIsWorksheetScreenFullWidth] = useState(false);

    const [sections, setSections] = useState([]);
    const [isEditSection, setIsEditSection] = useState(false);
    const [editSectionId, setEditSectionId] = useState(null);
    const [editSectionName, setEditSectionName] = useState(null);

    const [isEditWorksheet, setIsEditWorksheet] = useState(false);
    const [editWorksheetId, setEditWorksheetId] = useState(null);
    const [editWorksheetText, setEditWorksheetText] = useState(null);
    const [editMarks, setEditMarks] = useState(null);

    const [selectedWorksheets, setSelectedWorksheets] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const {selectedClass} = useSelector((state) => state.classes);
    const {selectedSubject} = useSelector((state) => state.subjects);
    const {selectedTopic} = useSelector((state) => state.topics);
    const {selectedSubTopic} = useSelector((state) => state.subtopics);
    const {worksheetList}  = useSelector((state) => state.worksheets);
    const {selectedPaper} = useSelector((state) => state.papers);

    const parser = new DOMParser();

    const dispatch = useDispatch();
 
  

    const handlePaperPreview = ()=>{
      if(paperPreview){
        setPaperPreview(false)
      }
      else{
        setPaperPreview(true)
      }
    }
   
    const handleWorksheetScreenWidth = ()=>{
      if(isWorksheetScreenFullWidth){
        setIsWorksheetScreenFullWidth(false)
      }
      else{
        setIsWorksheetScreenFullWidth(true)
      }
    }
   
    const enableEditSection = (sectionId, sectionName)=>{
        setEditSectionId(sectionId)
        setEditSectionName(sectionName)
        setIsEditSection(true)
    }

    const disableEditSection = ()=>{
      setEditSectionId(null)
      setEditSectionName(null)
      setIsEditSection(false)
    }

    const enableEditWorksheet = (sectionId, worksheetId, worksheetText, marks)=>{
      setEditSectionId(sectionId)
      setEditWorksheetId(worksheetId)
      setEditWorksheetText(worksheetText)
      setEditMarks(marks)
      setIsEditWorksheet(true)
    }

    const disableEditWorksheet = ()=>{
      setEditSectionId(null)
      setEditWorksheetId(null)
      setEditWorksheetText(null)
      setEditMarks(null)
      setIsEditWorksheet(false)
    }

    const deleteSection = (sectionId)=>{
      let updatedSections = selectedPaper.sections.filter((item)=>{
            if(item.id != sectionId){
              return item;
            }  
      })
     
      dispatch({
        'type': UPDATE_PAPER_SECTION,
        'payload': [...updatedSections]
      })
      
  }

 

    

  const handleSectionChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const sectionId = selectedOption.getAttribute('data-id');

  
    console.log(sectionId)
    setSelectedSectionId(sectionId)
  };

  // Function to handle when a worksheet is selected or deselected
  const handleSelectWorksheet = (worksheet) => {
    setSelectedWorksheets((prevSelected) => {
      // Check if the worksheet is already selected
      const isAlreadySelected = prevSelected.some(
        (selected) => selected.id === worksheet.id
      );

      if (isAlreadySelected) {
        // If it's already selected, remove it from the array
        return prevSelected.filter((selected) => selected.id !== worksheet.id);
      } else {
        // If it's not selected, add it to the array
        return [...prevSelected, worksheet];
      }
    });
  };


  // Function to move selected worksheets to a specific section
  const handleMoveToSection = () => {

    const updatedSections = selectedPaper.sections.map((section) => {
        
          if (section.id == selectedSectionId) {
            console.log(selectedWorksheets)
            console.log(section)
            const filterWorksheets =  selectedWorksheets
            .filter((item1) => {
                  let worksheetExists = section.worksheets.some(item2 => item1.id == item2.id)
                  if(!worksheetExists){
                    return item1
                  }
              });
            return {
              ...section,
              worksheets: [
                ...section.worksheets,
                ...filterWorksheets,
              ],
            };
          }
          return section;

        })

    // setSections(updatedSections);

    dispatch({
      'type': UPDATE_PAPER_SECTION,
      'payload': updatedSections
    })


    // Clear the temporary selected worksheets after moving
    setSelectedWorksheets([]);
  };


  // Function to handle deleting a worksheet from a section
  
  const handleDeleteWorksheet = (sectionId, worksheetId)=>{
    let updatedSections = selectedPaper.sections.map((section)=>{
          if(section.id == sectionId){
            let worksheets = section.worksheets.filter((worksheet)=>{
               if(worksheet.id != worksheetId){
                  return worksheet
               }
            })
            section.worksheets = worksheets
          }  
          return section 
    })
   
    dispatch({
      'type': UPDATE_PAPER_SECTION_WORKSHEET,
      'payload': [...updatedSections]
    })
    
}

  return (
    <div className="wrapper">
    
      <div className="row">
        <Sidebar></Sidebar>
        <div className="col-md-9 col-lg-10 content">
        <div className="row">

          <div className={ isWorksheetScreenFullWidth ? 'col-md-12' : 'col-md-6'} style={ paperPreview ? { display: 'none' } : { display: 'block'} }>
            <div className="content-section">
              <div className="container">
              
                <div className="row">
                        <div className="col-md-8">
                          <h4>Exam Worksheets</h4>
                        </div>
                        <div className="col-md-4">
                              <button className="btn btn-primary btn-sm" onClick={handleWorksheetScreenWidth}> {isWorksheetScreenFullWidth ? 'Exit Full Screen' : 'Full Screen'} </button>
                        </div>
                  </div>

                <div className="class-info">
                    <p><span><strong>Class:</strong>  {selectedClass}  </span>
                    <span><strong>Subject:</strong>  {selectedSubject}  </span></p>
                    <p><span><strong>Topic:</strong>  {selectedTopic}  </span>
                    <span><strong>SubTopic:</strong>  {selectedSubTopic}</span></p>
                </div>

        
                <div className="row mb-4 mt-2">
                  <div className="col-md-8">
                    <select className="form-select" onChange={handleSectionChange}>
                        <option>Select Section</option>
                        {selectedPaper.sections &&  selectedPaper.sections.map((Item, i)=>( 
                            <option key={Item.id}  data-id={Item.id}>{Item.section_name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <button className="btn btn-primary btn-sm" onClick={handleMoveToSection}>Move Worksheet</button>
                  </div>
                </div>
        
                {/* {worksheetList.data && worksheetList.data.map((Item, i)=>(
              
                    <div className="mb-4 worksheet">
                        <div className="row mb-2">
                            <div className="col-md-9">
                            <Wrapper id={i} worksheet_text={Item.worksheet_text}></Wrapper>
                              
                                
                            </div>
                            <div className="col-md-3">
                                <p style={{marginBottom: 0}}><strong>Marks:</strong> {Item.marks}</p>
                                <label className="form-check-label" for="q4">Select</label>
                                <input type="checkbox" className="form-check-input" value={Item.id}
                                    checked={selectedWorksheets.some(
                                        (selected) => selected.id === Item.id
                                    )}
                                    onChange={() => handleSelectWorksheet(Item)}>

                                </input>
                            </div>
                        </div>
                    </div>
                ))} */}

                
                {worksheetList.data && worksheetList.data.map((worksheet, i)=>(

                <div className="question" key={worksheet.id}>
                          <div className="row">
                                <div className="col-md-9">
                                <p><strong>{i + 1}. {worksheet.title}</strong> </p>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-check-label" for="q4">Select</label>
                                    <input type="checkbox" className="form-check-input" value={worksheet.id}
                                        checked={selectedWorksheets.some(
                                            (selected) => selected.id === worksheet.id
                                        )}
                                        onChange={() => handleSelectWorksheet(worksheet)}>

                                    </input>
                                </div>
                          </div>
                            
                          {worksheet.contents.map((content, i)=>(
                            <div className="row">
                                  <div className="col-md-12">
                                    {content.textContent ? (
                                      <p>{content.textContent}</p> 
                                    ) : (
                                      <div></div>
                                    )}

                                    {content.imageContent ? (
                                      <img
                                        src={`${content.imageContent}`}
                                        alt="Preview"
                                        className="img-fluid w-80"
                                      />
                                    ) : (
                                      <div></div>
                                    )}
                                    
                                  </div>
                                  {/* <div className="col-md-4">
                                    {content.imageContent ? (
                                      <img
                                      src={`http://localhost/questions-creator/public/uploads/${content.imageContent}`}
                                      alt="Preview"
                                      className="img-fluid w-50"
                                    />
                                    ) : (
                                      <div></div>
                                    )}
                            
                                  </div> */}
                              </div>
                              
                          ))}  
          
                </div>

                ))}

            </div>
            </div>
          </div>

        
          <div className={ paperPreview ? 'col-md-12' : 'col-md-6'} style={ isWorksheetScreenFullWidth ? { display: 'none' } : { display: 'block'} }>
            <div className="content-section">
              <div className="container">

                  <div className="row mb-2">
                        <div className="col-md-9">
                          <h4>Exam Paper </h4>
                        </div>
                        <div className="col-md-3">
                              <button className="btn btn-primary btn-sm" onClick={handlePaperPreview}> {paperPreview ? 'Exit Full Screen' : 'Full Screen'} </button>
                        </div>
                  </div>
              
              

                    <Papertitle></Papertitle>

                    <PaperSubTitle></PaperSubTitle>
                
                    <CreateSection></CreateSection>
                    
                    {isEditSection ? (<EditSection sectionId={editSectionId} editSectionName={editSectionName} disableEditSection={disableEditSection}></EditSection>) : (<div></div>)}

                    {isEditWorksheet ? (<EdiWorksheet 
                      sectionId={editSectionId} 
                      worksheetId={editWorksheetId} 
                      editWorksheetText={editWorksheetText} 
                      editMarks={editMarks} 
                      disableEditWorksheet={disableEditWorksheet}
                    ></EdiWorksheet>) : (<div></div>)}

                

              {selectedPaper.sections &&  selectedPaper.sections.map((Item, i)=>(
                        <div className="mb-4 question" key={Item.id}>

                          <div className="row mb-4">
                                  <div className="col-md-10">
                                      <div className="d-flex justify-content-start">
                                            <p className='h4'>{Item.section_name}</p>
                                      </div>
                                  </div>
                                  <div className="col-md-2">
                                      <div className="d-flex justify-content-end ">
                                      <i className="fas fa-trash"></i> {/* Example icon */}
                                          <button className="btn float-right" onClick={()=>{enableEditSection(Item.id, Item.section_name)}}><i className="bi bi-pencil-square"></i></button>
                                          <button className="btn float-right" onClick={()=>{deleteSection(Item.id)}}><i className="bi bi-trash"></i> </button>
                                      </div>
                                  </div>
                          </div>

                          
                                            

                        {Item.worksheets && Item.worksheets.map((worksheet, i)=>(
                              <div className="row mb-2">

                                <div className="col-md-9">
                                    <p><strong>{i + 1}. {worksheet.title}</strong> </p>
                                </div>
                                <div className="col-md-3 text-end">
                                    
                                    <button className="btn" onClick={()=>{enableEditWorksheet(Item.id, worksheet.id)}} ><i className="bi bi-pencil-square"></i></button>
                                    <button className="btn" onClick={() =>
                                                handleDeleteWorksheet(Item.id, worksheet.id)
                                            }><i className="bi bi-trash"></i> </button>
                                </div>

                                {worksheet.contents.map((content, i)=>(
                                    <div className="row">
                                          <div className="col-md-12">
                                            {content.textContent ? (
                                              <p>{content.textContent}</p> 
                                            ) : (
                                              <div></div>
                                            )}
                                            {content.imageContent ? (
                                              <img
                                              src={`${content.imageContent}`}
                                              alt="Preview"
                                              className="img-fluid w-80"
                                              />
                                            ) : (
                                              <div></div>
                                            )}  
                                              
                                          </div>
                                          {/* <div className="col-md-4">
                                            {content.imageContent ? (
                                              <img
                                              src={`http://localhost/questions-creator/public/uploads/${content.imageContent}`}
                                              alt="Preview"
                                              className="img-fluid w-50"
                                            />
                                            ) : (
                                              <div></div>
                                            )}
                                    
                                          </div> */}
                                      </div>
                                ))}  

                            </div>
                        ))} 
                
                        
                    </div>
              ))}
                
          
                
        
          
                
            </div>
            </div>
          </div>
        </div>
        </div>
      
      </div>

  </div>
  );
};

export default CreateWorksheetPdf;
