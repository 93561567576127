import { fetchClasses, fetchSubjects } from '../api';

// Action Types 
export const GET_CLASSES = 'GET_CLASSES';
export const SELECT_CLASS = 'SELECT_CLASS';
export const GET_SUBJECTS = 'GET_SUBJECTS'; 
export const SELECT_CLASS_ID = 'SELECT_CLASS_ID';  



// Action Creators
export const getClasses = () => async (dispatch) => {
  try {
    const response = await fetchClasses();
    if(response){
      dispatch({ type: GET_CLASSES, payload: response.data });
    }
    
  } catch (error) {
    console.error('Error fetching classes:', error);
  }
}; 



export const selectClass = (selectedClass) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_CLASS, payload: selectedClass });
  } catch (error) {
    console.error('Error updating class:', error);
  }
};


