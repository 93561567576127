import { fetchPapers } from '../api';

// Action Types
export const GET_PAPERS = 'GET_PAPERS';
export const SELECT_PAPER = 'SELECT_PAPER';
export const UPDATE_PAPER_SECTION = 'UPDATE_PAPER_SECTION';
export const UPDATE_PAPER_TITLE = 'UPDATE_PAPER_TITLE';
export const UPDATE_PAPER_SUBTITLE = 'UPDATE_PAPER_SUBTITLE';
export const UPDATE_PAPER_SECTION_QUESTION = 'UPDATE_PAPER_SECTION_QUESTION';
export const UPDATE_PAPER_SECTION_WORKSHEET = 'UPDATE_PAPER_SECTION_WORKSHEET';

// Action Creators
export const getPapers = (subjectId) => async (dispatch) => {
//   try {
//     const response = await fetchPapers(subjectId);
//     dispatch({ type: GET_PAPERS, payload: response.data });
//   } catch (error) {
//     console.error('Error fetching Papers:', error);
//   }
};

export const selectPaper = (selectedPaper) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_PAPER, payload: selectedPaper });
  } catch (error) {
    console.error('Error updating Paper:', error);
  }
};


