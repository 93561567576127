import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table } from 'react-bootstrap';
import SidebarPdf from "../../components/SidebarPdf";
import { useSelector, useDispatch } from 'react-redux';
import { createSampleWorksheet, fetchSampleWorksheet, updateSampleWorksheet } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';

const CreateSampleWorksheet = () => {
 
  const navigate = useNavigate();
  const { id } = useParams(); 
  let sampleWorksheetId = id;
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const [formData, setFormData] = useState({
    'id': null,
    'institute_id': '',
    'InstituteName': '',
    'class_id': '',
    'className': '',
    'subject_id': '',
    'subjectName': '',
    'title': '',
    'pdf': null
  });


  // Fetch sample worksheets
  useEffect(() => {
    const fetchSample = async () => {
      try {
        const response = await fetchSampleWorksheet(sampleWorksheetId)
        setFormData({...response.data.data.sampleWorksheet})
        
      } catch (error) {
        console.error('Error fetching worksheet:', error);
      }
    };
    if(sampleWorksheetId){
      fetchSample();
    }
    
  }, [sampleWorksheetId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, pdf: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.class_id = selectedClassId;
    formData.className = selectedClass;
    formData.subject_id = selectedSubjectId;
    formData.subjectName = selectedSubject;
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await createSampleWorksheet(data)
      alert('Sample Worksheet added successfully!');
      navigate('/sample-worksheets')
      
    } catch (error) {
      console.error('Error saving sample worksheet:', error);
      alert('Failed to save sample worksheet.');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await updateSampleWorksheet(formData.id, data)
      alert('Sample worksheet added successfully!');
      navigate('/sample-worksheets')
      
    } catch (error) {
      console.error('Error saving sample worksheet:', error);
      alert('Failed to save sample worksheet.');
    }
  };



  return (
    <div className="wrapper"> 
    <div className="row">
    <SidebarPdf></SidebarPdf>
        <div className="container mt-2 col-md-9 col-lg-10">
        <h3>Add Sample Worksheet</h3>

<div className="class-info">
    <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
    <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
</div>

      <Form onSubmit={handleSubmit}>
        
        
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </Form.Group>
       
        <Form.Group className="mb-3">
          <Form.Label>PDF File</Form.Label>
          <Form.Control type="file" name="pdf" onChange={handleFileChange} accept=".pdf" />
        </Form.Group>

        {formData.id ? (
          <Button variant="primary" onClick={handleUpdate} >
            Update Sample Worksheet
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Add Sample Worksheet
          </Button>
        )}
        
      </Form>

      

     
      </div>
      </div>
    </div>
  );
};

export default CreateSampleWorksheet;
