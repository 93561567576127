import { 
  GET_PAPERS, SELECT_PAPER, UPDATE_PAPER_SECTION, 
  UPDATE_PAPER_TITLE, UPDATE_PAPER_SUBTITLE, 
  UPDATE_PAPER_SECTION_QUESTION, UPDATE_PAPER_SECTION_WORKSHEET } from '../actions/paperActions';

const initialState = {
  paperList: [],
  loading: false,
  error: null,
  selectedPaper: {

    'school_name': 'XYZ High School',
    'title': '',
    'subtitle': '',
    'sections': []

  }
};

const paperReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAPERS:
      return { ...state, paperList: action.payload, loading: false };
    case SELECT_PAPER:
      return { ...state, selectedPaper: action.payload, loading: false };   
    case UPDATE_PAPER_SECTION:
      return { ...state, selectedPaper: {...state.selectedPaper, sections: action.payload }, loading: false };   
    case UPDATE_PAPER_TITLE:
      return { ...state, selectedPaper: {...state.selectedPaper, title: action.payload }, loading: false }; 
    case UPDATE_PAPER_SUBTITLE:
      return { ...state, selectedPaper: {...state.selectedPaper, subtitle: action.payload }, loading: false };    
    case UPDATE_PAPER_SECTION_QUESTION:
      return { ...state, selectedPaper: {...state.selectedPaper, sections: action.payload }, loading: false };  
    case UPDATE_PAPER_SECTION_WORKSHEET:
      return { ...state, selectedPaper: {...state.selectedPaper, sections: action.payload }, loading: false };  
    default:
      return state;
  }
};

export default paperReducer;
