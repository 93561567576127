import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_PAPER_SECTION_QUESTION } from '../actions/paperActions';

const EdiQuestion = ({sectionId, questionId, editQuestionText, editMarks, disableEditQuestion}) => {
    const [questionText, setQuestionText] = useState(editQuestionText);
    const [marks, setMarks] = useState(editMarks);
    const {selectedPaper} = useSelector((state) => state.papers);
    const dispatch = useDispatch();
    
    const updateQuestion = ()=>{
        let updatedSections = selectedPaper.sections.map((section)=>{
              if(section.id == sectionId){
                let questions = section.questions.map((question)=>{
                   if(question.id == questionId){
                        question.question_text = questionText;
                        question.marks = marks;
                   }
                   return question
                })
                section.questions = questions
              }  
              return section 
        })
       
        dispatch({
          'type': UPDATE_PAPER_SECTION_QUESTION,
          'payload': [...updatedSections]
        })
        disableEditQuestion()
    }

    
    const handleQuestionText = (e) => {
        setQuestionText(e.target.value);
    };

    const handleMarks = (e) => {
        setMarks(e.target.value);
    };

    return(
        <div className="row mb-2">
            <div className="col-md-8">
                <input type="text" className="form-control" value={questionText} onChange={handleQuestionText}   placeholder="Enter question" required/>
                <input type="text" className="form-control" value={marks} onChange={handleMarks}   placeholder="Enter marks" required/>
            </div>
            <div className="col-md-4">
                <button className="btn btn-primary btn-sm" onClick={updateQuestion}>Save</button>
            </div>
        </div>
    )
    

   
};

export default EdiQuestion