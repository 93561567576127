import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import SidebarPdf from "../../components/SidebarPdf";
import { fetchQuestionPapers } from '../../api';

import { useNavigate } from 'react-router-dom'

const AdminQuestionPaperList = () => {
  const [questions, setQuestions] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);
  const {selectedSubTopic} = useSelector((state) => state.subtopics);

  const navigate = useNavigate();

  // Fetch questions from API on load
  useEffect(() => {

    const getQuestionPapers = async()=>{
      if(selectedClassId != 0 && selectedSubjectId != 0){
        try{
          const response = await fetchQuestionPapers(selectedClassId, selectedSubjectId)
          console.log('response')
          console.log(response)
          setQuestions(response.data.data)  
        }
        catch(error){
          console.log(error)
        }
      }
    }

    getQuestionPapers()
    
  }, [selectedClassId, selectedSubjectId]);

  // Delete a question
  const deleteQuestion = (id) => {
    axios
      .post(`http://localhost/questions-creator/public/api/question-paper/delete/${id}`, {},  {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
      })
      .then(() => {
        setQuestions(questions.filter((question) => question.id !== id));
      })
      .catch((error) => console.error("Error deleting question:", error));
  };

  return (
    <div className="wrapper">
    
      <div className="row">
          <SidebarPdf></SidebarPdf>
          <div className="container mt-4 col-md-9 col-lg-10">
            <h3>Question Paper List</h3>

            <div className="class-info">
                <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
                <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
            </div>


            {/* question Table */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>School Name</th>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Status</th>
                  <th>Download Count</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={question.id}>
                    <td>{index + 1}</td>
                    <td>{question.schoolName}</td>
                    <td>{question.title}</td>
                    <td>{question.subtitle}</td>
                    <td>{question.status}</td>
                    <td>{question.downloadCount}</td>
                    <td>
                      <Button
                       style={{marginRight: 10}}
                        variant="primary"
                        size="sm"
                        onClick={() => { navigate(`/create-paper-pdf/${question.id}`);}}
                      >
                        Create Pdf
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteQuestion(question.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
      </div>
    </div>
  );
};

export default AdminQuestionPaperList;
