import { GET_SUBJECTS, SELECT_SUBJECT, RESET_SUBJECT, SELECT_SUBJECT_ID } from '../actions/subjectActions';

const initialState = {
  subjectList: [],
  loading: false,
  error: null,
  selectedSubject: '',
  selectedSubjectId: 0
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBJECTS:
      return { ...state, subjectList: action.payload, selectedSubject: '', loading: false };
    case SELECT_SUBJECT:
      return { ...state, selectedSubject: action.payload, loading: false };  
    case RESET_SUBJECT:
      return { ...state, subjectList: action.payload, loading: false };   
    case SELECT_SUBJECT_ID:
      return { ...state, selectedSubjectId: action.payload, loading: false };   
    default:
      return state;
  }
};

export default subjectReducer;
