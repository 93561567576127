import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import SidebarPdf from "../../components/SidebarPdf";
import { fetchWorksheetsPdf } from '../../api';

const WorksheetPdfList = () => {
  const [worksheets, setWorksheets] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);
  

  // Fetch worksheets from API on load
  useEffect(() => {

    const getWorksheetsPdf = async()=>{
      if(selectedClassId != 0 && selectedSubjectId != 0){
        try{
          const response = await fetchWorksheetsPdf(selectedClassId, selectedSubjectId)
          console.log('response')
          console.log(response)
          setWorksheets(response.data.data)  
        }
        catch(error){
          console.log(error)
        }
      }
    }

    getWorksheetsPdf()
    
  }, [selectedClassId, selectedSubjectId]);


  
  return (
    <div className="wrapper">
    
      <div className="row">
          <SidebarPdf></SidebarPdf>
          <div className="container mt-4 col-md-9 col-lg-10">
            <h3>Worksheet Paper List</h3>

            <div className="class-info">
                <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
                <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
            </div>


            {/* Worksheet Table */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                 
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Status</th>
                  <th>Download Count</th>
                  <th>Download Link</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {worksheets.map((worksheet, index) => (
                  <tr key={worksheet.id}>
                    <td>{index + 1}</td>
              
                    <td>{worksheet.title}</td>
                    <td>{worksheet.subtitle}</td>
                    <td>{worksheet.status}</td>
                    <td>{worksheet.downloadCount}</td>
                    <td>
                      {worksheet.downloadLink == '' ? (<div></div>) : (
                        <a href={worksheet.downloadLink} download={worksheet.downloadLink} target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary" >Download PDF </a>
                      )}
                    
                    </td>
                    {/* <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteWorksheet(worksheet.id)}
                      >
                        Delete
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
      </div>
    </div>
  );
};

export default WorksheetPdfList;
