import axios from 'axios';

// Login Action
export const login = (phone, password) => async (dispatch) => {
  
  try {
    const responce = await axios.post('http://localhost/questions-creator/public/login', { phone, password }, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // dispatch({
    //   type: 'LOGIN_SUCCESS',
    //   payload: responce.data.data,
    // });

    console.log(Response)

    localStorage.setItem('token', responce.data.data.token);

    
  } catch (err) {
    dispatch({
      type: 'LOGIN_FAIL',
    });
  }
};

export const loginWithLocalStorage = () => async (dispatch) => {
  try {
    
    const token = localStorage.getItem('token');
    const data = {
      'token': token
    }
    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: data,
    });
    
  } catch (err) {
    dispatch({
      type: 'LOGIN_FAIL',
    });
  }
};

// Load Teacher Action
export const loadTeacher = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch({ type: 'AUTH_ERROR' });
    return;
  }

  try {
    const res = await axios.get('http://localhost:8080/profile', {
      headers: { Authorization: token },
    });

    dispatch({
      type: 'TEACHER_LOADED',
      payload: res.data.teacher,
    });
  } catch (err) {
    dispatch({
      type: 'AUTH_ERROR',
    });
  }
};



// Logout Action
export const logout = () => (dispatch) => {
  localStorage.removeItem('token');
  dispatch({ type: 'LOGOUT' });
};
