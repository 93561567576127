import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getWorksheets} from '../../actions/worksheetActions'
import "../../styles/worksheet-list.css"
import Sidebar from '../../components/Sidebar';

const Worksheets = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {worksheetList} = useSelector((state) => state.worksheets);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics);
  const { teacher } = useSelector((state) => state.auth);

  const handleEdit = (id) => {
    navigate(`/edit-worksheet/${id}`);
  };
  

  const deleteWorksheet = async (id) => {
    if (!window.confirm('Are you sure you want to delete this worksheet?')) return;

    try {
      await axios.delete(`http://localhost/questions-creator/public/api/worksheet/${id}`);
      dispatch(getWorksheets(selectedSubTopicId))
      alert('Worksheet deleted successfully');
    } catch (error) {
      console.error('Error deleting worksheet:', error);
      alert('Failed to delete worksheet');
    }
  };

  
  return (
    <div className="wrapper">
    
      <div className="row">
          <Sidebar></Sidebar>
          <div className=" col-md-9 col-lg-10 content">
            
            
          <div className="col-md-12">
          <div className="content-section">
              
              <div className="container">
                
                  
                      <h2>Worksheet List</h2>
                      
                    
                  
          {/* <div className="mb-3">
              <button className="btn btn-success">Add New Question</button>
          </div> */}
          
              
                  

                
                  {worksheetList.data && worksheetList.data.map((worksheet, i)=>(

                      <div className="question" key={worksheet.id}>

                                <div className="row">
                                  <div className="col-md-8"> 
                                  <p><strong>{i+1}. {worksheet.title}</strong> </p>
                                    {worksheet.contents.map((content)=>(
                                      <div className="row" key={content.id}>
                                            <div className="col-md-12">
                                                <p>{content.textContent}</p> 
                                                {content.imageContent ? (
                                                  <img
                                                  src={`${content.imageContent}`}
                                                  alt="Preview"
                                                  className="img-fluid w-80"
                                                />
                                                ) : (
                                                  <div></div>
                                                )}
                                                
                                            </div>
                                         
                                        </div>
                                        
                                        
                                    ))}  
                                  </div>
                                  <div className="col-md-4 ">
                                    <div className="action-buttons">
                                      {teacher && teacher.role == 'studentsTeacher' ? (
                                         <button className="btn btn-primary btn-sm" onClick={() => handleEdit(worksheet.id)}>Edit</button>
                                      ) : ('')}
                                    </div>
                                  </div>
                                </div>
                        

                      </div>

                  ))}
                  
          
              
              </div>



          </div>
          </div>



          </div>
      </div>
    </div>
  );
};

export default Worksheets;
