import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useRef } from 'react';
import { UPDATE_PAPER_SECTION_QUESTION, UPDATE_PAPER_SECTION } from '../../actions/paperActions';

import Papertitle from '../../components/PaperTitle'
import CreateSection from '../../components/CreateSection'
import PaperSubTitle from '../../components/PaperSubTitle';
import EditSection from '../../components/EditSection';
import EdiQuestion from '../../components/EditQuestion';
import Sidebar from '../../components/Sidebar';

import 'bootstrap-icons/font/bootstrap-icons.css';
import "../../styles/question-list.css"
import '../../styles/CreatePaper.css'
import { redirect, useNavigate  } from 'react-router-dom';

const Wrapper = ({ id, questionString }) => {
  const question = JSON.parse(questionString);
  if(question.type == 'multiple'){
    question.options = JSON.parse(question.options)
  }
  console.log('question')
  console.log(question)
  return(
    <div>
        <p>{id+1}. <span className='mt-4 2rem' dangerouslySetInnerHTML={{ __html: question.question }} ></span></p>
        {question.type === "multiple" && (
              <ul>
                {question.options.map((option, idx) => (
                  <li key={idx}>{option}</li>
                ))}
              </ul>
        )}
    </div>
  )

   
};
const CreatePaper = () => {
  
    const [paperPreview, setPaperPreview] = useState(false);
    const [isQuestionScreenFullWidth, setIsQuestionScreenFullWidth] = useState(false);

    const [sections, setSections] = useState([]);
    const [isEditSection, setIsEditSection] = useState(false);
    const [editSectionId, setEditSectionId] = useState(null);
    const [editSectionName, setEditSectionName] = useState(null);

    const [isEditQuestion, setIsEditQuestion] = useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const [editQuestionText, setEditQuestionText] = useState(null);
    const [editMarks, setEditMarks] = useState(null);

    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState(null);

    const {selectedClass} = useSelector((state) => state.classes);
    const {selectedSubject} = useSelector((state) => state.subjects);
    const {selectedTopic} = useSelector((state) => state.topics);
    const {selectedSubTopic, selectedSubTopicId} = useSelector((state) => state.subtopics);
    const {questionList} = useSelector((state) => state.questions);
    const {selectedPaper} = useSelector((state) => state.papers);

    const parser = new DOMParser();

    const dispatch = useDispatch();
 
  

    const handlePaperPreview = ()=>{
      if(paperPreview){
        setPaperPreview(false)
      }
      else{
        setPaperPreview(true)
      }
    }
   
    const handleQuestionScreenWidth = ()=>{
      if(isQuestionScreenFullWidth){
        setIsQuestionScreenFullWidth(false)
      }
      else{
        setIsQuestionScreenFullWidth(true)
      }
    }
   
    const enableEditSection = (sectionId, sectionName)=>{
        setEditSectionId(sectionId)
        setEditSectionName(sectionName)
        setIsEditSection(true)
    }

    const disableEditSection = ()=>{
      setEditSectionId(null)
      setEditSectionName(null)
      setIsEditSection(false)
    }

    const enableEditQuestion = (sectionId, questionId, questionText, marks)=>{
      setEditSectionId(sectionId)
      setEditQuestionId(questionId)
      setEditQuestionText(questionText)
      setEditMarks(marks)
      setIsEditQuestion(true)
    }

    const disableEditQuestion = ()=>{
      setEditSectionId(null)
      setEditQuestionId(null)
      setEditQuestionText(null)
      setEditMarks(null)
      setIsEditQuestion(false)
    }

    const deleteSection = (sectionId)=>{
      let updatedSections = selectedPaper.sections.filter((item)=>{
            if(item.id != sectionId){
              return item;
            }  
      })
     
      dispatch({
        'type': UPDATE_PAPER_SECTION,
        'payload': [...updatedSections]
      })
      
  }

 

    // Function to handle when a question is selected or deselected
  const handleSelectQuestion = (question) => {
    setSelectedQuestions((prevSelected) => {
      // Check if the question is already selected
      const isAlreadySelected = prevSelected.some(
        (selected) => selected.id === question.id
      );

      if (isAlreadySelected) {
        // If it's already selected, remove it from the array
        return prevSelected.filter((selected) => selected.id !== question.id);
      } else {
        // If it's not selected, add it to the array
        return [...prevSelected, question];
      }
    });
  };

  const handleSectionChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const sectionId = selectedOption.getAttribute('data-id');

  
    console.log(sectionId)
    setSelectedSectionId(sectionId)
  };


  // Function to move selected questions to a specific section
  const handleMoveToSection = () => {

    const updatedSections = selectedPaper.sections.map((section) => {
        
          if (section.id == selectedSectionId) {
            const filterQuestions =  selectedQuestions
            .filter((item1) => {
                  let questionExists = section.questions.some(item2 => item1.id == item2.id)
                  if(!questionExists){
                    return item1
                  }
              });
            return {
              ...section,
              questions: [
                ...section.questions,
                ...filterQuestions,
              ],
            };
          }
          return section;

        })

    // setSections(updatedSections);

    dispatch({
      'type': UPDATE_PAPER_SECTION,
      'payload': updatedSections
    })


    // Clear the temporary selected questions after moving
    setSelectedQuestions([]);
  };


  // Function to handle deleting a question from a section
  
  const handleDeleteQuestion = (sectionId, questionId)=>{
    let updatedSections = selectedPaper.sections.map((section)=>{
          if(section.id == sectionId){
            let questions = section.questions.filter((question)=>{
               if(question.id != questionId){
                  return question
               }
            })
            section.questions = questions
          }  
          return section 
    })
   
    dispatch({
      'type': UPDATE_PAPER_SECTION_QUESTION,
      'payload': [...updatedSections]
    })
    
}

  return (
    <div className="wrapper">
    
      <div className="row">
          <Sidebar></Sidebar>
          <div className="col-md-9 col-lg-10 content">
          <div className="row">

            <div className={ isQuestionScreenFullWidth ? 'col-md-12' : 'col-md-6'} style={ paperPreview ? { display: 'none' } : { display: 'block'} }>
              <div className="content-section">
                <div className="container">
                
                  <div className="row">
                          <div className="col-md-8">
                            <h4>Exam Questions</h4>
                          </div>
                          <div className="col-md-4">
                                <button className="btn btn-primary btn-sm" onClick={handleQuestionScreenWidth}> {isQuestionScreenFullWidth ? 'Exit Full Screen' : 'Full Screen'} </button>
                          </div>
                    </div>

                  <div className="class-info">
                      <p><span><strong>Class:</strong>  {selectedClass}  </span>
                      <span><strong>Subject:</strong>  {selectedSubject}  </span></p>
                      <p><span><strong>Topic:</strong>  {selectedTopic}  </span>
                      <span><strong>SubTopic:</strong>  {selectedSubTopic}</span></p>
                  </div>

          
                  <div className="row mb-4 mt-2">
                    <div className="col-md-8">
                      <select className="form-select" onChange={handleSectionChange}>
                          <option>Select Section</option>
                          {selectedPaper.sections &&  selectedPaper.sections.map((Item, i)=>( 
                              <option key={Item.id}  data-id={Item.id}>{Item.section_name}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <button className="btn btn-primary btn-sm" onClick={handleMoveToSection}>Move Question</button>
                    </div>
                  </div>
          
                  {questionList && questionList.map((Item, i)=>(
                
                      <div className="mb-4 question">
                          <div className="row mb-2">
                              <div className="col-md-9">
                              <Wrapper id={i} questionString={JSON.stringify(Item)}></Wrapper>
                                
                                  
                              </div>
                              <div className="col-md-3">
                                  <p style={{marginBottom: 0}}><strong>Marks:</strong> {Item.marks}</p>
                                  <label className="form-check-label" for="q4">Select</label>
                                  <input type="checkbox" className="form-check-input" value={Item.id}
                                      checked={selectedQuestions.some(
                                          (selected) => selected.id === Item.id
                                      )}
                                      onChange={() => handleSelectQuestion(Item)}>

                                  </input>
                              </div>
                          </div>
                      </div>
                  ))}

                  


              </div>
              </div>
            </div>

          
            <div className={ paperPreview ? 'col-md-12' : 'col-md-6'} style={ isQuestionScreenFullWidth ? { display: 'none' } : { display: 'block'} }>
              <div className="content-section">
                <div className="container">

                    <div className="row mb-2">
                          <div className="col-md-9">
                            <h4>Exam Paper </h4>
                          </div>
                          <div className="col-md-3">
                                <button className="btn btn-primary btn-sm" onClick={handlePaperPreview}> {paperPreview ? 'Exit Full Screen' : 'Full Screen'} </button>
                          </div>
                    </div>
                
                

                      <Papertitle></Papertitle>

                      <PaperSubTitle></PaperSubTitle>
                  
                      <CreateSection></CreateSection>
                      
                      {isEditSection ? (<EditSection sectionId={editSectionId} editSectionName={editSectionName} disableEditSection={disableEditSection}></EditSection>) : (<div></div>)}

                      {isEditQuestion ? (<EdiQuestion 
                        sectionId={editSectionId} 
                        questionId={editQuestionId} 
                        editQuestionText={editQuestionText} 
                        editMarks={editMarks} 
                        disableEditQuestion={disableEditQuestion}
                      ></EdiQuestion>) : (<div></div>)}

                  

                {selectedPaper.sections &&  selectedPaper.sections.map((Item, i)=>(
                          <div className="mb-4 question" key={Item.id}>

                            <div className="row mb-4">
                                    <div className="col-md-10">
                                        <div className="d-flex justify-content-start">
                                              <p className='h4'>{Item.section_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="d-flex justify-content-end ">
                                        <i className="fas fa-trash"></i> {/* Example icon */}
                                            <button className="btn float-right" onClick={()=>{enableEditSection(Item.id, Item.section_name)}}><i className="bi bi-pencil-square"></i></button>
                                            <button className="btn float-right" onClick={()=>{deleteSection(Item.id)}}><i className="bi bi-trash"></i> </button>
                                        </div>
                                    </div>
                            </div>
                                              

                          {Item.questions && Item.questions.map((question, i)=>(
                                <div className="row mb-2">

                                  <div className="col-md-9">
                                    <Wrapper id={i} questionString={JSON.stringify(question)}></Wrapper>
                                  </div>
                                  <div className="col-md-3 text-end">
                                      <p style={{marginBottom: 0}}><strong>Marks:</strong> {question.marks}</p>
                                      <button className="btn" onClick={()=>{enableEditQuestion(Item.id, question.id, question.question, question.marks)}} ><i className="bi bi-pencil-square"></i></button>
                                      <button className="btn" onClick={() =>
                                                  handleDeleteQuestion(Item.id, question.id)
                                              }><i className="bi bi-trash"></i> </button>
                                  </div>
                              </div>
                          ))} 
                  
                          
                      </div>
                ))}
                  
            
                  
          
            
                  
              </div>
              </div>
            </div>
          </div>
          </div>
      </div>

    </div>
  );
};

export default CreatePaper;
