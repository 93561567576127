import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';

import logger from 'redux-logger'
import classReducer from './reducers/classReducer';
import subjectReducer from './reducers/subjectReducer';
import topicReducer from './reducers/topicReducer';
import subtopicReducer from './reducers/subTopicReducer';
import questionReducer from './reducers/questionReducer';
import paperReducer from './reducers/paperReducer';
import authReducer from './reducers/authReducer';
import worksheetReducer from './reducers/worksheetReducer';

const middleware = [thunk];



const rootReducer = combineReducers({
  classes: classReducer,
  subjects: subjectReducer,
  topics: topicReducer,
  subtopics: subtopicReducer,
  questions: questionReducer,
  worksheets: worksheetReducer,
  papers: paperReducer,
  auth: authReducer,
});

const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger)
);



export default store;
