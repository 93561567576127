import { fetchQuestions, createQuestion } from '../api';

// Action Types
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const CREATE_QUESTION = 'CREATE_QUESTION';


const parseQuestionString = (questionInput)=>{
  
  let start = 0
  let latexStr = ''
  let questionString = ''
  

  for(let i = 0; i < questionInput.length; i++){
     
     if(questionInput[i] == '$' && start == 0){
          start = 1
          continue
     }
     if(questionInput[i] == '$' && start == 1){
       console.log('latexStr')
       console.log(latexStr)

       const svgNode = window.MathJax.tex2svg(`'${latexStr}'`); // This returns a full MathJax-generated DOM structure
       let svg = svgNode.querySelector('svg');

       console.log(svg)
       questionString = questionString + svg.outerHTML
       
       latexStr = ''
       start = 0
       continue
     }
     if(start == 1){
          latexStr = latexStr + questionInput[i]
     }
     if(start == 0){
          questionString = questionString + questionInput[i]
     }

     

     // console.log(questionInput[i])
  }

  return questionString;

}  


const modifyQuestionss = (data)=>{
    const modifiedData = data.map((item)=>{
         item.question = parseQuestionString(item.question)
         console.log(item)
         return item
    })

    return modifiedData
}


// Action Creators
export const getQuestions = (subjectId) => async (dispatch) => {
  try {
    const response = await fetchQuestions(subjectId);

    response.data.data = modifyQuestionss(response.data.data)
    console.log('questions')
    console.log(response.data.data)
    dispatch({ type: GET_QUESTIONS, payload: response.data.data });
  } catch (error) {
    console.error('Error fetching Questions:', error);
  }
};

export const postQuestion = (subTopicId, questionString) => async (dispatch) => {
  try {
    const data = {
      "subtopic_id": subTopicId,
      "question_text": questionString,
      "answer_text": "testing"
    }
    const response = await createQuestion(data);
    dispatch({ type: GET_QUESTIONS, payload: response.data });
  } catch (error) {
    console.error('Error fetching Questions:', error);
  }
};

export const selectQuestion = (selectedQuestion) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_QUESTION, payload: selectedQuestion });
  } catch (error) {
    console.error('Error updating Question:', error);
  }
};


