// src/pages/Home.js
import React from 'react';
import '../styles/Home.css'

const Home = () => {
  return (
    <>
    
    

    <div className="hero">
        <div className="container">
            <h2>Empower Your Teaching with Effective Question Paper Creation</h2>
            <p>Create papers and worksheets for your students' needs.</p>
            <a href="#" className="btn btn-primary btn-lg">Get Started</a>
        </div>
    </div>


    <div className="container my-5">
        <h2 className="text-center mb-4">Our Services</h2>
        <div className="row justify-content-center">
          
            <div className="col-md-4 mb-4">
                <div className="service-card">
                    <div className="service-icon">
                        <i className="bi bi-pencil-square" ></i>
                    </div>
                    <h5 className="mt-3">Question Creation</h5>
                    <p>Custom questions tailored to your curriculum.</p>
                </div>
            </div>
          
            <div className="col-md-4 mb-4">
                <div className="service-card">
                    <div className="service-icon">
                        <i className="bi bi-file-earmark-text" ></i>
                    </div>
                    <h5 className="mt-3">Worksheet Creation</h5>
                    <p>Generate worksheets with various formats.</p>
                </div>
            </div>
       
            <div className="col-md-4 mb-4">
                <div className="service-card">
                    <div className="service-icon">
                        <i className="bi bi-book" ></i>
                    </div>
                    <h5 className="mt-3">Resource Library</h5>
                    <p>Access a library of teaching resources and materials.</p>
                </div>
            </div>
        </div>
    </div>


    {/* <div className="container my-5">
        <h2 className="text-center mb-4">Pricing Plans</h2>
        <div className="row justify-content-center">
     
            <div className="col-md-4 mb-4">
                <div className="service-card">
                    <h5>Basic Plan</h5>
                    <div className="pricing-price">/month</div>
                    <ul className="list-unstyled">
                        <li>10 Questions per month</li>
                        <li>Basic Question Types</li>
                    </ul>
                    <button className="btn btn-primary">Choose Plan</button>
                </div>
            </div>
        
            <div className="col-md-4 mb-4">
                <div className="service-card">
                    <h5>Standard Plan</h5>
                    <div className="pricing-price">month</div>
                    <ul className="list-unstyled">
                        <li>50 Questions per month</li>
                        <li>All Question Types</li>
                    </ul>
                    <button className="btn btn-primary">Choose Plan</button>
                </div>
            </div>
     
            <div className="col-md-4 mb-4">
                <div className="service-card">
                    <h5>Premium Plan</h5>
                    <div className="pricing-price">month</div>
                    <ul className="list-unstyled">
                        <li>Unlimited Questions</li>
                        <li>All Question Types</li>
                    </ul>
                    <button className="btn btn-primary">Choose Plan</button>
                </div>
            </div>
        </div>
    </div> */}

 
    <div className="container my-5">
        <h2 className="text-center mb-4">What Our Users Say</h2>
        <div className="row justify-content-center">
            <div className="col-md-8 text-center">
                <p className="blockquote">
                    "This platform has transformed my teaching! I can create custom questions and worksheets quickly and easily."
                </p>
            </div>
        </div>
    </div>
    
    
    
    </>
  );
};

export default Home;
