import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';
import '../../styles/PaperView.css'
import { worksheetPdfUpload, fetchWorksheetPdf } from '../../api';
import WorksheetUpload from './WorksheetUpload';

  
  const WorksheetHtmlView = ({paperString}) => {
    const [questionNo, setQuestionNo] = useState(0)
    const [pixel, setPixel] = useState(0)
    const [worksheetStyle, setWorksheetStyle] = useState([])

    const elementRef = useRef(null);
    const contentRef = useRef(null);
    const marginBottomRef = useRef(null)
    const rowRef = useRef(null)

    const [height, setHeight] = useState(0);

    console.log('paperString');
    console.log(paperString)

    if(paperString == ''){
      return(
        <div className="container mt-5">
          <h1>Data not available</h1>
        </div>
      )
    } 

    
    let paperData = JSON.parse(paperString)
    console.log('paperData');
    console.log(paperData)
    let worksheets = JSON.parse(paperData.worksheets)
    
   

    worksheets = worksheets.map((worksheet, i)=>{
        let styleData = worksheetStyle.find((style)=>{
            if(style.rowNo == i + 1){
               return style;
            }
        })
        if(styleData){
          worksheet.marginBottom = styleData.marginBottom
        }
        else{
          worksheet.marginBottom = 0
        }
        return worksheet
    })

    console.log('worksheets') 
    console.log(worksheets) 

    const deleteStyle = (index)=>{

      let styleArray = worksheetStyle.filter((value, i)=>{
          if(index != i){
            return value
          }
      })

      setWorksheetStyle([...styleArray])

    }
    

    const dataArray = [
      { id: 1, title: "Page 1", content: "Content for the first page." },
      { id: 2, title: "Page 2", content: "Content for the second page." },
      { id: 3, title: "Page 3", content: "Content for the third page." },
      { id: 4, title: "Page 4", content: "Content for the third page." },
      { id: 5, title: "Page 5", content: "Content for the third page." },
      { id: 6, title: "Page 6", content: "Content for the third page." },
      { id: 7, title: "Page 7", content: "Content for the third page." },
      { id: 8, title: "Page 8", content: "Content for the third page." },
      { id: 9, title: "Page 8", content: "Content for the third page." },
      { id: 10, title: "Page 8", content: "Content for the third page." },
    ];

    const addStyle = ()=>{
      let rowNo = rowRef.current.value;
      let marginBottom = marginBottomRef.current.value
      let data = {
        'rowNo' : rowNo,
        'marginBottom': marginBottom
      }
      setWorksheetStyle([...worksheetStyle, data])
    }

  // const updateHeight = () => {
  //   if (elementRef.current) {
  //     console.log('height')
  //     console.log(elementRef.current.offsetHeight);
  //   }
  // };

 
    
    const handleDownload = () => {
      const element = contentRef.current;
      const options = {
        // margin:       0.5,              // Custom margins
        filename:     'download.pdf',   // Output PDF name
        image:        { type: 'jpeg', quality: 0.98 }, // Image type and quality
        html2canvas:  { scale: 2, useCORS: true }, // Canvas options for quality and cross-origin images
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' } // PDF format
      };
      html2pdf().set(options).from(element).save();
    };
  
    const handleQuestionNo = (e)=>{
      setQuestionNo(e.target.value);
    }

    const handlePixel = (e)=>{
      setPixel(e.target.value)
    }

    // const uploadPdf = async (e) => {

    //   e.target.files[0]
    //   console.log('hi')
    //   console.log(file)
    //   const formData = new FormData();
    //   formData.append('image', file);
    //   try {
    //     const response = await axios.post('http://localhost/questions-creator/public/api/worksheet/uploadImage', formData, {
    //       headers: { 'Content-Type': 'multipart/form-data' },
    //     });
    //     console.log(response)
    //     updateContentSection(id, 'imageContent', response.data.data.image_url);
    //   } catch (error) {
    //     console.error("Error uploading image:", error);
    //   }
    // };

    const generateAndUploadPdf = async () => {
      try {
        // Convert the HTML content to a PDF blob
        const content = contentRef.current;
        const pdfBlob = await html2pdf()
          .set({
            margin: 10,
            filename: "generated.pdf",
            image:        { type: 'jpeg', quality: 0.98 }, // Image type and quality
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          })
          .from(content)
          //.toPdf()
          .outputPdf("blob");
  
        // Send the PDF file to the server
        const formData = new FormData();
        formData.append("pdf", pdfBlob, "generated.pdf");
  
        const response = await worksheetPdfUpload(paperData.id, formData)
   
        if (response.status == 200) {
          alert("PDF uploaded successfully!");
        } else {
          alert("Failed to upload PDF.");
        }
      } catch (error) {
        console.error("Error generating or uploading PDF:", error);
        alert("An error occurred.");
      }
    };
   
    
    return (
      <div className="container"    >
  
       <div className='row' >
        <div className='col-md-2'>

              <div>
                {dataArray.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      border: "2px dashed #000",
                      // padding: "20px",
                      // marginBottom: "20px",
                      height: "1090px", // A4 page height
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      pageBreakAfter: index === dataArray.length - 1 ? "auto" : "always",
                    }}
                  >
                    <h1>{index + 1}</h1>
                  </div>
                ))}
            </div>

        </div>

        <div className='col-md-8' style={{maxWidth: '790px'}} >
            <div className='container' ref={contentRef} style={{paddingLeft: 30, paddingRight: 30}}>
                <header className="text-center mb-2">
                  <h2>{paperData.school_name}</h2>
                  <h4>{paperData.title}</h4> 
                  <p>{paperData.subtitle}</p>
                </header>
          
             
                  <section className="mb-2">
                      
                        {worksheets.map((worksheet, index) => {
                 
                            return ( 
                    
                                <div  key={worksheet.id}  style={{'marginBottom':  Number(worksheet.marginBottom) }}>
                                      <div className="row">
                                          <div className="col-md-12">
                                                  <p><strong>{index + 1}. {worksheet.title}</strong> </p>
                                          </div>
                                      </div>
                                  
                                        {worksheet.contents.map((content, i)=>{
                                    
                                            return (<div className="row">
                                                        <div className="col-md-12">
                                                            <p>{content.textContent}</p> 
                                                            {content.imageContent ? (
                                                               <img
                                                                  src={`${content.imageContent}`}
                                                                  alt="Preview"
                                                                  className="img-fluid"
                                                                  // style={{width: '80%'}}
                                                                /> 
                                                            ) : (
                                                              <div></div>
                                                            )}
                                                            
                                                        </div>
                                                    </div>
                                                  ) 

                                          } )}  
                
                                </div>
                              
                            )

                        })}  
                      
                      
                      </section>
                   
            </div>
        </div>

        <div className='col-md-2'>
                <p>
                    <span>Row No</span>
                    <input type="text" ref={rowRef} className="form-control" value={questionNo} onChange={handleQuestionNo}  placeholder="Enter Question No" required/>
                </p>
                <p>
                    <span>Margin Bottom</span>  
                    <input type="text" ref={marginBottomRef} className="form-control" value={pixel} onChange={handlePixel}  placeholder="Pixels" required/>    
                </p>

                <p>
                    <button onClick={addStyle}>Add</button>     
                </p>

                {worksheetStyle.map((style, i)=>{
                  return(
                    <div>
                       <p><span>Row No: </span>{style.rowNo}</p>
                       <p><span>Margin Bottom: </span>{style.marginBottom}</p>
                       <p><button onClick={()=>{deleteStyle(i)}}>Delete</button></p>
                    </div>
                  )
                })}


                        <p style={{marginTop:100}}>
                            <button onClick={handleDownload}>Download PDF</button>
                        </p>
                        <p>
                              <WorksheetUpload id={paperData.id}></WorksheetUpload>
                        </p>
                        <p>
                            {/* <button onClick={generateAndUploadPdf}> Upload PDF</button> */}
                        </p>
                
                
        </div>

       </div>

       
  
      </div>
    );
  };
  

// Document Component
const WorksheetPdfPreview = () => {
 
 
  const [paper, setPaper] = useState('')

  
  const { id } = useParams(); 

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await fetchWorksheetPdf(id)
        console.log('response')
        console.log(response) 
  
        setPaper(JSON.stringify(response.data.data.paper))
      } catch (error) {
        console.error('Error fetching worksheet:', error);
      }
    };
    if(id){
      fetchQuestion(); 
    }
    
  }, [id]);

  
  
  return(
    <div>
      <div>
          <WorksheetHtmlView paperString={paper} ></WorksheetHtmlView>
      </div>
   
    </div>
  );

}

  export default WorksheetPdfPreview;

