import React from "react"
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
const Navigation = ()=>{
        const storedToken = localStorage.getItem("token");
        //const role = localStorage.getItem("role");
        const { teacher } = useSelector((state) => state.auth);

        console.log('teacher')
        console.log(teacher)

        //const {selectedClass} = useSelector((state) => state.classes);

        if(teacher && teacher.role == 'studentsTeacher'){
            return(
                <nav className="ml-auto">
                   
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/questions">Question List</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-question">Create Question</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-paper">Create Question Paper</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-view">Question Paper View</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-list">Question Paper List</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/sample-papers">Sample Papers</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-sample-paper">Add Sample Paper</Link>
                </nav>
            )
        }

       else if(teacher &&  teacher.role == 'kidsTeacher'){
            return(
                <nav className="ml-auto">
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheets">Worksheet List</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-worksheet-pdf">Worksheet Pdf</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-view">Worksheet View</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-pdf-list">Worksheet Pdf List</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/sample-worksheets">Sample Worksheets</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-sample-worksheet">Add Sample Worksheet</Link>
                </nav>
            )
       }

       else if(teacher &&  teacher.role == 'teachersAdmin'){
        return(
            <nav className="ml-auto">
                
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/questions">Question List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-question">Create Question</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-paper">Create Question Paper</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-view">Question Paper View</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-list">Question Paper List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/sample-papers">Sample Papers</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-sample-paper">Add Sample Paper</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/admin-question-paper-list">Admin Question Paper List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheets">Worksheet List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-worksheet">Create Worksheet</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-worksheet-pdf">Worksheet Pdf</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-view">Worksheet View</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-pdf-list">Worksheet Pdf List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-pdf-view">Worksheet Pdf Creater</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/admin-worksheet-pdf-list">Admin Worksheet Pdf List</Link>
            </nav>
        )
       }    
      
       else {
        return(
            <nav className="ml-auto">
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/">Home</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/contact">Contact</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/feature">Features</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/about">About</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/tutorial">Tutorials</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/faq">Faq</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/blog">Blog</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/register">Register</Link>
            </nav>
        )
       }
        
        {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-form">Question Form</Link> */}
        {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/pdf-view">Question Pdf Creator</Link> */}
        {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="content-manager">Content Manager</Link> */}
        {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="worksheet-editor">Worksheet Editor</Link>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="content-form">Content Form</Link> */}
        {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/html-pdf-view">Html Pdf View</Link> */}
       
   
}
export default Navigation