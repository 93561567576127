import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import "../../styles/question-list.css"
import { json, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';


const Wrapper = ({ id, questionString }) => {
  const question = JSON.parse(questionString);
  if(question.type == 'multiple'){
    question.options = JSON.parse(question.options)
  }
  console.log('question')
  console.log(question)
  return(
    <div>
        <p>{id+1}. <span className='mt-4 2rem' dangerouslySetInnerHTML={{ __html: question.question }} ></span></p>
        {question.type === "multiple" && (
              <ul>
                {question.options.map((option, idx) => (
                  <li key={idx}>{option}</li>
                ))}
              </ul>
        )}
    </div>
  )

   
};


const Questions = () => {
  
  const navigate = useNavigate();
  const {questionList} = useSelector((state) => state.questions);

  const handleEdit = (id) => {
    navigate(`/edit-question/${id}`);
  };
  

  return (
    <div className="wrapper">
    
    <div className="row">

      <Sidebar></Sidebar>

    <div className=" col-md-9 col-lg-10 content">
       
       
    <div className="col-md-12">
    <div className="content-section">
        
        <div className="container">
          
            
                <h2>Exam Questions</h2>
                
               
            
    {/* <div className="mb-3">
        <button className="btn btn-success">Add New Question</button>
    </div> */}
    
         
            

           
            {questionList && questionList.map((question, i)=>(

                <div className="question" key={question.id}>
                      
                    <div className="row">
                      <div className="col-md-8">
                        <Wrapper id={i} questionString={JSON.stringify(question)}></Wrapper>
                      </div>
                      <div className="col-md-4 ">
                        <div className="action-buttons">
                          <button className="btn btn-primary btn-sm" onClick={() => handleEdit(question.id)}>Edit</button>
                          <button className="btn btn-danger btn-sm">Delete</button>
                        </div>
                        <span className="marks">
                            <p style={{marginBottom: 0}}><strong>Marks:</strong> {question.marks}</p>
                        </span>
                      </div>
                    </div>

                </div>

            ))}
            
    
         
        </div>



    </div>
    </div>



    </div>
    </div>
  </div>

  );
};

export default Questions;
