import { GET_CLASSES, SELECT_CLASS, SELECT_CLASS_ID } from '../actions/classActions';

const initialState = {
  classList: [],
  loading: false,
  error: null,
  selectedClass: '',
  selectedClassId: 0
};

const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLASSES:
      return { ...state, classList: action.payload, loading: false };
    case SELECT_CLASS:
      return { ...state, selectedClass: action.payload, loading: false };  
    case SELECT_CLASS_ID:
        return { ...state, selectedClassId: action.payload, loading: false }; 
    default:
      return state;
  }
};

export default classReducer;
