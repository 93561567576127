import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import SidebarPdf from "../../components/SidebarPdf";
import { fetchQuestionPapers } from '../../api';

const QuestionPaperList = () => {
  const [questions, setQuestions] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);
  

  // Fetch questions from API on load
  useEffect(() => {

    const getQuestionPapers = async()=>{
      if(selectedClassId != 0 && selectedSubjectId != 0){
        try{
          const response = await fetchQuestionPapers(selectedClassId, selectedSubjectId)
          console.log('response')
          console.log(response)
          setQuestions(response.data.data)  
        }
        catch(error){
          console.log(error)
        }
      }
    }

    getQuestionPapers()
    
  }, [selectedClassId, selectedSubjectId]);


  
  return (
    <div className="wrapper">
    
      <div className="row">
          <SidebarPdf></SidebarPdf>
          <div className="container mt-4 col-md-9 col-lg-10">
            <h3>Question Paper List</h3>

            <div className="class-info">
                <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
                <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
            </div>


            {/* question Table */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                 
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Status</th>
                  <th>Download Count</th>
                  <th>Download Link</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={question.id}>
                    <td>{index + 1}</td>
              
                    <td>{question.title}</td>
                    <td>{question.subtitle}</td>
                    <td>{question.status}</td>
                    <td>{question.downloadCount}</td>
                    <td>
                      {question.downloadLink == '' ? (<div></div>) : (
                        <a href={question.downloadLink} download={question.downloadLink} target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary" >Download PDF </a>
                      )}
                    
                    </td>
                    {/* <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteQuestion(question.id)}
                      >
                        Delete
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
      </div>
    </div>
  );
};

export default QuestionPaperList;
