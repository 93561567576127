import { fetchWorksheets, createWorksheet } from '../api';

// Action Types
export const GET_WORKSHEETS = 'GET_WORKSHEETS';
export const SELECT_WORKSHEET = 'SELECT_WORKSHEET';
export const CREATE_WORKSHEET = 'CREATE_WORKSHEET';

export const UPDATE_PAPER_SECTION = 'UPDATE_PAPER_SECTION';
export const UPDATE_PAPER_TITLE = 'UPDATE_PAPER_TITLE';
export const UPDATE_PAPER_SUBTITLE = 'UPDATE_PAPER_SUBTITLE';



// Action Creators
export const getWorksheets = (subTopicId) => async (dispatch) => {
  try {
    const response = await fetchWorksheets(subTopicId);
    console.log('worksheet')
    console.log(subTopicId)
    console.log(response.data.data)
    dispatch({ type: GET_WORKSHEETS, payload: response.data });
  } catch (error) {
    console.error('Error fetching Worksheets:', error);
  }
};

export const postWorksheet = (subTopicId, worksheetString) => async (dispatch) => {
  try {
    const data = {
      "subtopic_id": subTopicId,
      "worksheet_text": worksheetString,
      "answer_text": "testing"
    }
    const response = await createWorksheet(data);
    dispatch({ type: GET_WORKSHEETS, payload: response.data });
  } catch (error) {
    console.error('Error fetching Worksheets:', error);
  }
};

export const selectWorksheet = (selectedWorksheet) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_WORKSHEET, payload: selectedWorksheet });
  } catch (error) {
    console.error('Error updating Worksheet:', error);
  }
};


