import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_PAPERS, SELECT_PAPER, UPDATE_PAPER_SECTION } from '../actions/paperActions';

const EditSection = ({sectionId, editSectionName, disableEditSection}) => {
    const [sectionName, setSectionName] = useState(editSectionName);
    const {selectedPaper} = useSelector((state) => state.papers);

    const section = selectedPaper.sections.find((item)=> item.id == sectionId)
    const dispatch = useDispatch();
  
    
    const updateSection = ()=>{
        let updatedSections = selectedPaper.sections.map((item)=>{
              if(item.id == sectionId){
                item.section_name = sectionName;
                return item;
              }  
              return item 
        })
       
        dispatch({
          'type': UPDATE_PAPER_SECTION,
          'payload': [...updatedSections]
        })
        disableEditSection()
    }

    
    const handleSection = (e) => {
        setSectionName(e.target.value);
    };

    return(
        <div className="row mb-2">
            <div className="col-md-10">
                <input type="text" className="form-control" value={sectionName} onChange={handleSection}   placeholder="Enter section" required/>
            </div>
            <div className="col-md-2">
                <button className="btn btn-primary text-end btn-sm" onClick={updateSection}>Save</button>
            </div>
        </div>
    )
    

   
};

export default EditSection