import { fetchSubjects } from '../api';

// Action Types 
export const GET_SUBJECTS = 'GET_SUBJECTS';
export const SELECT_SUBJECT = 'SELECT_SUBJECT';
export const RESET_SUBJECT = 'RESET_SUBJECT';
export const SELECT_SUBJECT_ID = 'SELECT_SUBJECT_ID';

// Action Creators
export const getSubjects = (classId) => async (dispatch) => {
  try {
    const response = await fetchSubjects(classId);

    console.log('response')
    console.log(response)
    dispatch({ type: GET_SUBJECTS, payload: response.data });
  } catch (error) {
    console.error('Error fetching Subjects:', error);
  }
};

export const selectSubject = (selectedSubject) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_SUBJECT, payload: selectedSubject });
  } catch (error) {
    console.error('Error updating subject:', error);
  }
};


