import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithLocalStorage } from './actions/authActions';

import Home from './pages/Home';
import Questions from './pages/questionPages/Questions';
import CreateQuestion from './pages/questionPages/CreateQuestion';
import CreatePaper from './pages/questionPages/CreatePaper';
import PdfPreview from './pages/questionPages/PdfPreview';
import PrivateHeader from './components/PrivateHeader';
import Login from './pages/Login';
import Signup from './pages/Signup';
import TeacherProfile from './pages/TeacherProfile';
import PrivateRoute from './components/PrivateRoute';
// import ContentManager from './pages/ContentManager';
import Worksheets from './pages/worksheetPages/Worksheets';
import CreateWorksheet from './pages/worksheetPages/CreateWorksheet';
// import WorksheetEditor from './pages/WorksheetEditor';
import CreateWorksheetPdf from './pages/worksheetPages/CreateWorksheetPdf';

import WorksheetPdfPreview from './pages/worksheetPages/WorksheetPdfPreview';

import WorksheetView from './pages/worksheetPages/WorksheetView';

import WorksheetPdfList from './pages/worksheetPages/WorksheetPdfList';

import QuestionPaperView from './pages/questionPages/QuestionPaperView';

import QuestionPaperList from './pages/questionPages/QuestionPaperList';

import AdminQuestionPaperList from './pages/questionPages/AdminQuestionPaperList';

import QuestionForm from './pages/questionPages/QuestionForm';

import SamplePapers from './pages/samplePapers/SamplePapers';

import CreateSamplePaper from './pages/samplePapers/CreateSamplePaper';

import SampleWorksheets from './pages/sampleWorksheets/SampleWorksheets';

import CreateSampleWorksheet from './pages/sampleWorksheets/CreateSampleWorksheet';

import AdminWorksheetPdfList from './pages/worksheetPages/AdminWorksheetPdfList';


function App() {

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(loginWithLocalStorage());
  }, [dispatch]);


  return (

    <Router >
      <div>
        {/* Header */}
        
        <PrivateHeader></PrivateHeader>
        {/* Main content */}
      
          <Routes>

            <Route path="/" element={<Home />} />

            <Route path="/login" element={<Login />} />

            <Route path="/signup" element={<Signup />} />

            <Route path="/profile" element={<PrivateRoute><TeacherProfile /></PrivateRoute>}/>

            <Route path="/questions" element={<PrivateRoute><Questions /></PrivateRoute>}/>

            <Route path="/create-question" element={<PrivateRoute><QuestionForm /></PrivateRoute>}/>

            <Route path="/edit-question/:id" element={<PrivateRoute><QuestionForm /></PrivateRoute>}/>

            <Route path="/create-paper" element={<PrivateRoute><CreatePaper /></PrivateRoute>}/>

            <Route path="/question-paper-view" element={<PrivateRoute><QuestionPaperView /></PrivateRoute>}/>

            <Route path="/question-paper-list" element={<PrivateRoute><QuestionPaperList /></PrivateRoute>}/>
           
            <Route path="/create-paper-pdf/:id" element={<PrivateRoute><PdfPreview /></PrivateRoute>}/>

            <Route path="/sample-papers" element={<PrivateRoute><SamplePapers /></PrivateRoute>}/>

            <Route path="/add-sample-paper" element={<PrivateRoute><CreateSamplePaper /></PrivateRoute>}/>

            <Route path="/edit-sample-paper/:id" element={<PrivateRoute><CreateSamplePaper /></PrivateRoute>}/>

            <Route path="/admin-question-paper-list" element={<PrivateRoute><AdminQuestionPaperList /></PrivateRoute>}/>


            <Route path="/worksheets" element={<PrivateRoute><Worksheets /></PrivateRoute>}/>
            
            <Route path="/create-worksheet" element={<PrivateRoute><CreateWorksheet /></PrivateRoute>}/>

            <Route path="/edit-worksheet/:id" element={<PrivateRoute><CreateWorksheet /></PrivateRoute>}/>

            <Route path="/create-worksheet-pdf" element={<PrivateRoute><CreateWorksheetPdf /></PrivateRoute>}/>

            <Route path="/worksheet-view" element={<PrivateRoute><WorksheetView /></PrivateRoute>}/>

            <Route path="/worksheet-pdf-view/:id" element={<PrivateRoute><WorksheetPdfPreview /></PrivateRoute>}/>

            <Route path="/worksheet-pdf-list" element={<PrivateRoute><WorksheetPdfList /></PrivateRoute>}/>

            <Route path="/sample-worksheets" element={<PrivateRoute><SampleWorksheets /></PrivateRoute>}/>

            <Route path="/add-sample-worksheet" element={<PrivateRoute><CreateSampleWorksheet /></PrivateRoute>}/>

            <Route path="/edit-sample-worksheet/:id" element={<PrivateRoute><CreateSampleWorksheet /></PrivateRoute>}/>
        
            <Route path="/admin-worksheet-pdf-list" element={<PrivateRoute><AdminWorksheetPdfList /></PrivateRoute>}/>

          </Routes>
     

        {/* Footer */}
        <footer className="bg-primary text-white text-center py-3">
          &copy; 2024 www.studentgrows.com
        </footer>
      </div>
    </Router>

  );
}

export default App;
