import { GET_QUESTIONS } from '../actions/questionActions';

const initialState = {
  questionList: [],
  loading: false,
  error: null,
  selectedQuestion: ''
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return { ...state, questionList: action.payload, loading: false };
    default:
      return state;
  }
};

export default questionReducer;
